import React from "react"
import ContactForm from "../../components/contact-form";

const ContactFormSection = () => (
    <section className="cw-container contact-form-section">
        <div className="contact-form-section__form-column">
            <ContactForm/>
        </div>
        <div className="contact-form-section__contact-details-column">
            <div className="contact-details">
                <h3>US Office</h3>
                <p>CodeWave LLC</p>
                <p>16192 Coastal Hwy, Lewes,</p>
                <p>DE 19958, United States</p>
                <p>phone: <a href="tel:0016319095771">+1 (631) 909 5771</a></p>
            </div>
            <div className="contact-details">
                <h3>PL Office</h3>
                <p>CodeWave Sp. z o.o.</p>
                <p>Krakowska 88a, Wrocław</p>
                <p>50-427, Poland</p>
                <p>phone: <a href="tel:0016319095771">+48 717 391 391</a></p>
            </div>
            <div className="contact-details">
                <a href="mailto:hello@codewave.pl">hello@codewave.pl</a>
            </div>
        </div>
    </section>
)

export default ContactFormSection