import React from "react"
import {navigate} from "gatsby"
import {load} from 'recaptcha-v3'
import {
    Configuration,
    ContentCodewaveContactFormApi,
    ContentMediaApi
} from '../api/lib';

const states = {
    EDITING: "EDITING",
    SAVING_FILE: "SAVING_FILE",
    SAVING: "SAVING",
    SAVED: "SAVED"
}

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: "",
                message: "",
                lastName: "",
                firstName: "",
                attachment: [],
                phoneNumber: "",
                adultConsent: "no",
                marketingConsent: "no",
                dataProcessingConsent: "no"
            },
            formErrors: {
                email: "",
                message: "",
                lastName: "",
                firstName: "",
                attachment: "",
                phoneNumber: "",
                adultConsent: "",
                marketingConsent: "",
                dataProcessingConsent: ""
            },
            state: states.EDITING,
            selectedFiles: [],
        }

        this.onChange = this.onChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onRemoveFile = this.onRemoveFile.bind(this);

        this.adultConsent = React.createRef();
        this.dataProcessingConsent = React.createRef();
        this.marketingConsent = React.createRef();
        this.attachment = React.createRef();

        this.acceptedFileTypes = [
            "application/pdf",
            "image/jpeg",
            "image/png",
        ];

        this.acceptedFileExtensions = [
            "jpg",
            "jpeg",
            "png",
            "pdf"
        ]
    }

    async componentDidMount() {
        this.recaptcha = await load('6LdtZ-gUAAAAANDHe7_2TdKKBeP2s2XOaoCyuU5t');
        this.recaptcha.showBadge();
    }

    async componentWillUnmount() {
        this.recaptcha.hideBadge();
    }

    render() {
        return <div className="contact-form">
            <form action="" className="form" onSubmit={this.onSubmit}>
                <div className="form__field">
                    <input
                        id="firstName"
                        name="firstName"
                        className="input-text js-input"
                        type="text"
                        onChange={this.onChange}
                        placeholder="First Name"
                        required
                        disabled={this.state.state !== states.EDITING}
                    />
                    {this.state.formErrors.firstName && <div
                        className="form__error">{this.state.formErrors.firstName}</div>}
                </div>
                <div className="form__field">
                    <input
                        id="lastName"
                        name="lastName"
                        className="input-text js-input"
                        type="text"
                        onChange={this.onChange}
                        placeholder="Last Name"
                        required
                        disabled={this.state.state !== states.EDITING}
                    />
                    {this.state.formErrors.lastName && <div
                        className="form__error">{this.state.formErrors.lastName}</div>}
                </div>
                <div className="form__field">
                    <input
                        id="phoneNumber"
                        name="phoneNumber"
                        className="input-text js-input"
                        type="number"
                        onChange={this.onChange}
                        placeholder="Phone number"
                        disabled={this.state.state !== states.EDITING}
                    />
                    {this.state.formErrors.phoneNumber && <div
                        className="form__error">{this.state.formErrors.phoneNumber}</div>}
                </div>
                <div className="form__field">
                    <input
                        id="email"
                        name="email"
                        className="input-text js-input"
                        type="email"
                        onChange={this.onChange}
                        placeholder="Email"
                        required
                        disabled={this.state.state !== states.EDITING}
                    />
                    {this.state.formErrors.email && <div
                        className="form__error">{this.state.formErrors.email}</div>}
                </div>
                <textarea
                    name="message"
                    id=""
                    rows="5"
                    placeholder="How can we help You?"
                    onChange={this.onChange}
                    disabled={this.state.state !== states.EDITING}
                />
                {this.state.formErrors.message && <div
                    className="form__error">{this.state.formErrors.message}</div>}
                <ul className="form__file-list">
                    {this.state.selectedFiles.map((file, index) =>
                        <li key={index}>
                            {file.name}
                            <button
                                type="button"
                                onClick={() => {
                                    this.onRemoveFile(file)
                                }}
                                className="btn btn--icon">
                                ✕
                            </button>
                        </li>
                    )}
                </ul>

                <div className="form__file-attachment">
                    <label className="btn btn--gray btn--small btn--attach"
                           disabled={this.state.state !== states.EDITING || this.state.selectedFiles.length >= 3}>
                        <input
                            type="file"
                            hidden
                            multiple
                            accept={this.acceptedFileTypes.join(', ')}
                            onChange={this.onFileChange}
                            ref={this.attachment}
                            disabled={this.state.state !== states.EDITING || this.state.selectedFiles.length >= 3}
                        />
                        Attach files
                    </label>
                    <p className="file-attachment--info">max. file size: <strong>10mb</strong> | max. number of files: <strong>3</strong> | supported file types: <strong>.pdf, .png, .jpg</strong></p>
                    {this.state.formErrors.attachment && <div
                        className="form__error">{this.state.formErrors.attachment}</div>}
                </div>
                <div className="consent-wrapper">
                    <div className="consent">
                        <input
                            type="checkbox"
                            id="adultConsent"
                            name="adultConsent"
                            required
                            onChange={this.onChange}
                            disabled={this.state.state !== states.EDITING}
                        />
                        <label ref={this.adultConsent} htmlFor="adultConsent">
                            * I confirm that I am over 18 years old.
                        </label>
                    </div>
                    {this.state.formErrors.adultConsent && <div
                        className="form__error">{this.state.formErrors.adultConsent}</div>}
                    <div className="consent">
                        <input
                            type="checkbox"
                            id="dataProcessingConsent"
                            name="dataProcessingConsent"
                            required
                            onChange={this.onChange}
                            disabled={this.state.state !== states.EDITING}
                        />
                        <label
                            ref={this.dataProcessingConsent}
                            htmlFor="dataProcessingConsent"
                        >
                            * I grant permission to CodeWave Sp. z o. o. to
                            store and
                            process my personal information for the purposes of
                            answering this contact request in compliance with EU
                            laws
                            and Codewave Privacy Policy.
                        </label>
                    </div>
                    {this.state.formErrors.dataProcessingConsent && <div
                        className="form__error">{this.state.formErrors.dataProcessingConsent}</div>}
                    <div className="consent">
                        <input
                            type="checkbox"
                            id="marketingConsent"
                            name="marketingConsent"
                            onChange={this.onChange}
                            disabled={this.state.state !== states.EDITING}
                        />
                        <label ref={this.marketingConsent}
                               htmlFor="marketingConsent">
                            I am willing to receive information about CodeWave
                            products
                            and services.
                        </label>
                    </div>
                    {this.state.formErrors.marketingConsent && <div
                        className="form__error">{this.state.formErrors.marketingConsent}</div>}
                </div>
                <button
                    type="submit"
                    className="btn btn--send"
                    disabled={this.state.state !== states.EDITING}
                >
                    {this.getSubmitButtonLabel()}
                </button>
                {this.state.formError &&
                <div className="form__error">{this.state.formError}</div>}
            </form>
        </div>
    }

    getSubmitButtonLabel() {
        if (this.state.state === states.SAVING_FILE) {
            return "Sending files"
        }
        if (this.state.state === states.SAVING) {
            return "Sending form"
        }
        if (this.state.state === states.SAVED) {
            return "Done..."
        }

        return "Submit";
    }

    onRemoveFile(file) {
        this.setState({
            selectedFiles: this.state.selectedFiles.filter(f => f !== file)
        })
    }

    onFileChange() {
        const fileInput = this.attachment.current;
        if (!fileInput) {
            return false;
        }
        let errors = [];
        let selectedFiles = this.state.selectedFiles;
        if (fileInput.files.length + selectedFiles.length > 3) {
            errors.push(`We accept only up to 3 files`)
        }
        Array.prototype.forEach.call(fileInput.files, file => {
            const sizeInMb = file.size / 1024 / 1024;
            if (selectedFiles.length >= 3) {
                return
            }

            if (sizeInMb > 10) {
                errors.push(`${file.name} is too large. Files cannot be larger than 10MB`)
                return;
            }
            const mimeTypeAccepted = this.acceptedFileTypes.indexOf(file.type) > -1;
            const extensionAccepted = this.acceptedFileExtensions.indexOf(file.name.split('.').pop()) > -1;
            if (!mimeTypeAccepted && !extensionAccepted) {
                errors.push(`${file.name} is not accepted. The only acccepted files are .jpg, .png, .pdf, .docx, .xlsx and .zip`)
                return;
            }

            selectedFiles.push(file)
        });
        this.setState({
            selectedFiles: [...selectedFiles],
            formErrors: {
                ...this.state.formErrors,
                attachment: errors.join(", ")
            }
        })
    }

    onChange(event) {
        const input = event.target;
        const type = input.type;
        const value = type === "checkbox" ? (input.checked ? "yes" : "no") : input.value;
        const name = input.name;

        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value
            }
        });
    }

    onSubmit(event) {
        event.preventDefault();
        this.doSubmit();
    }

    parseErrors(errors) {
        let newErrors = Object.assign({}, this.state.formErrors, Object.keys(errors).reduce((acc, key) => {
            acc[key] = errors[key][0];
            if (key.toLowerCase().indexOf("consent") > -1 && acc[key]) {
                acc[key] = "Sorry, but we only take ✓ as an answer. Otherwise we won't be able to respond to your message";
            }
            if (key.toLowerCase().indexOf("email") > -1 && acc[key]) {
                acc[key] = "Sorry, but we need a valid email here";
            }
            return acc
        }, {}))
        this.setState({
            formErrors: newErrors
        })
    }

    async doSubmit() {
        try {
            const apiConfig = new Configuration({
                apiKey: "e47d576fcd837428778b461aef681382"
            });
            let attachment = [];

            await Promise.all(this.state.selectedFiles.map(async (file, index) => {
                this.setState({
                    state: states.SAVING_FILE
                })
                let fileData = new FormData()
                fileData.append('file', file)
                fileData.append('type', file.type.indexOf('image') > -1 ? 'image' : 'file')
                //const fileToken = await
                // this.recaptcha.execute('send_contact_media_' + index)
                // fileData.append('recaptchaToken', fileToken)

                let uploadedFile = await fetch(`https://api.flotiq.com/api/media?auth_token=${apiConfig.apiKey}`, {
                    method: 'POST',
                    body: fileData
                }).then(res => {
                    if (!res.status) {
                        throw new Error(res);
                    }
                    if (res.status < 100 || res.status > 400) {
                        throw new Error(res);
                    }

                    return res.json()
                });
                const mediaApi = new ContentMediaApi(apiConfig);
                //const mediaToken = await
                // this.recaptcha.execute('send_contact_media_content_object_'
                // + index); uploadedFile.recaptchaToken = mediaToken;
                let mediaResult = await mediaApi.createMedia(uploadedFile);
                if (mediaResult.status > 100 && mediaResult.status < 300) {
                    attachment.push({
                        dataUrl: `/api/v1/content/_media/${uploadedFile.id}`,
                        type: "internal"
                    })
                }
                else {
                    if (mediaResult.status === 400) {
                        this.parseErrors(await mediaResult.json());
                    }
                    throw new Error("Couldn't save media content type")
                }
            }))
            this.setState({
                state: states.SAVING
            })
            let api = new ContentCodewaveContactFormApi(apiConfig);
            //const contactToken = await
            // this.recaptcha.execute('send_contact_content_object');
            let contactForm = {
                id: "CodewaveContactForm-" + Math.floor(Math.random() * 9999999999),
                adultConsentText: this.adultConsent.current.innerText,
                dataProcessingConsentText: this.dataProcessingConsent.current.innerText,
                marketingConsentText: this.marketingConsent.current.innerText,
                ...this.state.formData,
                attachment,
                //recaptchaToken: contactToken
            };
            await api.createCodewavecontactform(contactForm);
            this.setState({
                state: states.SAVED
            });
            navigate("/contact/thank-you");

        }
        catch (error) {
            let errorMessage = "Something went wrong. Please try again later or contact us via hello@flotiq.com"
            if (error && error.status === 400) {
                this.parseErrors(await error.json());
                errorMessage = "Please, make sure all fields are filled correctly"
            }

            console.error(error);
            this.setState({
                state: states.EDITING,
                formError: errorMessage
            })
        }

    }
}

export default ContactForm