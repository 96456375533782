import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql, useStaticQuery} from "gatsby";
import ContactFormSection from "../sections/contact/contact-form-section";

const AboutUsPage = () => {
    const data = useStaticQuery(graphql`
        query {
            contactSectionBG: file(relativePath: { eq: "backgrounds/cdwv-bg-intro-alt.svg" }) {
                publicURL
            }
        }
    `);

    let contactSectionStyle = {
        backgroundImage: `url(${data.contactSectionBG.publicURL})`
    }

    return (
        <Layout>
            <SEO title="Contact"/>
            <div className="subpage-main-wrapper">
                <div className="cw-container-fluid" style={contactSectionStyle}>
                    <div className="cw-container content-section contact-intro-section">
                        <h2 className="section-header section-header--colored">Send Us a Message</h2>
                        <p className="section-description">Fill out this simple form. Our team will contact you promptly to discuss next steps.</p>
                    </div>
                    <ContactFormSection/>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUsPage
